@media screen and (max-width: 960px) {
    .grid-lg {
        display: none !important;
    }
}

@media screen and (min-width: 960px) {
    .grid-sm {
        display: none !important;
    }
}
