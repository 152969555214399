@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@font-face {
    font-family: "Perpetua";
    src: local("Perpetua"), url(../fonts/Perpetua.otf) format("opentype");
}

@font-face {
    font-family: "Gil-Sans-Light";
    src: local("Gil-Sans-Light"),
        url(../fonts/Gill\ Sans\ Light.otf) format("opentype");
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

input[type="submit"] {
    font-family: "Gil-Sans-Light";
    background-color: #000;
    border: 0;
    color: #fff;
    padding: 24px;
    border-radius: 0px;
    width: 100%;
    text-transform: uppercase;
}

body {
    margin: 0;
    padding: 0;
    font-family: "perpeta", sans-serif;
}

/* "react-table" styling */

.thead-th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* padding-left: 1rem; */
}

.table-td {
    padding-left: 0rem;
}
.table-td-spacing {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.highlight-row:nth-child(odd) {
    background-color: #f9fafb;
}

.animate {
    transition: opacity 500ms ease-in-out;
    backface-visibility: hidden;
}

.carousel-container {
    position: relative;
    width: 100%;
    height: 92vh;
    overflow: hidden;
}

.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.image-scale {
    transform: scale(1.02);
    transition: transform 0.5s ease-in-out;
}

.image-scale:hover {
    transform: scale(1.05);
}

.animate {
    transition: opacity 500ms ease-in-out;
    backface-visibility: hidden;
}

.carousel-container {
    position: relative;
    width: 100%;
    height: 92vh;
    overflow: hidden;
}

.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}
