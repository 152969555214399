.animate {
    animation-duration: 3.2s;
    -webkit-animation-duration: 3.2s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-delay: 0ms;
    -webkit-animation-delay: 0ms;
    animation-name: fading;
    -webkit-animation-name: fading;
}

@keyframes fading {
    0%, 100% {
        opacity: 0.8; 
    }
    20%, 80% {
        opacity: 1; 
    }
}